import React /*, { useState }*/ from "react";
import {
//  Grid,
//  CircularProgress,
//  Typography,
//  Button,
//  Tabs,
//  Tab,
//  TextField,
//  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
//import classnames from "classnames";

// styles
//import useStyles from "./styles";

// logo
//import logo from "./logo.svg";
//import google from "../../images/google.svg";

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";

function Login(props) {
  //var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
//  var [isLoading, setIsLoading] = useState(false);

//  var [activeTabId, setActiveTabId] = useState(0);
//  var [nameValue, setNameValue] = useState("");
//  var [loginValue, setLoginValue] = useState("");
//  var [passwordValue, setPasswordValue] = useState("");
  
  loginUser(
          userDispatch,
          "a",
          "a",
          props.history,
          ()=>{},
          ()=>{},
        )
  return (<div>Cargando ...</div>)
/*
  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <Typography className={classes.logotypeText}>Informe CPL</Typography>
        <Typography className={classes.logotypeText}>Votaciones presenciales</Typography>
        <Typography className={classes.logotypeText}>Encuentros Ciudadanos</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Ingreso" classes={{ root: classes.tab }} />
          </Tabs>
          {activeTabId === 0 && (
            <React.Fragment>
              <Typography variant="h1" className={classes.greeting}>
                
              </Typography>
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Correo"
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Clave"
                type="password"
                fullWidth
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      loginValue.length === 0 || passwordValue.length === 0
                    }
                    onClick={() =>
                      loginUser(
                        userDispatch,
                        loginValue,
                        passwordValue,
                        props.history,
                        setIsLoading,
                        ()=>{},
                      )
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Ingresar
                  </Button>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
        <Typography color="primary" className={classes.copyright}>
          © 2020 CPL Kennedy/Techotiva.
        </Typography>
      </div>
    </Grid>
  );*/
}

export default withRouter(Login);
