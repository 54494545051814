import React, { Component } from 'react';

//import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import ImageGallery from 'react-image-gallery';


import "react-image-gallery/styles/css/image-gallery.css";



const dataImages = {
  "1": ["índice.jpeg", "uhui.jpeg"],
  "2": ["índgfhdrhyice.jpeg", "índice1.jpeg", "índice.jpeg", "juhuiy.jpeg"],
  "3": ["índice1.jpeg", "índice2.jpeg", "índice.jpeg"],
  "4": ["índ1ice.jpeg", "índice2.jpeg", "índice3.jpeg", "índice.jpeg", "índisfdce.jpeg", "jhjf.jpeg"],
  "5": ["índic4e.jpeg", "índice1.jpeg", "índice.jpeg", "índxvfxice.jpeg"],
  "6": ["índic566e.jpeg", "índice.jpeg", "índvvcvcice.jpeg"],
  "7": ["índghfdice.jpeg", "índic1e.jpeg", "índice.jpeg", "índigujvce.jpeg", "índixsdfzsce.jpeg", "ínfghcfhdice.jpeg"],
  "8": ["fszdrfee.jpeg", "índice1.jpeg", "índice.jpeg"],
  "9": ["íncbfbdice.jpeg", "índice1.jpeg", "índice2.jpeg", "índice.jpeg", "índuohice.jpeg", "sdeszgtfj.jpeg"],
  "10": ["índice.jpeg", "índidgvdfce.jpeg", "índihcfrtyhce.jpeg", "índizsdcfzsce.jpeg"],
  "11": ["6874968.jpeg", "índ534ice.jpeg", "índice1.jpeg", "índice2.jpeg", "índice3.jpeg", "índice4.jpeg", "índice5.jpeg", "índice.jpeg"],
  "12": ["índffgmice.jpeg", "índice.jpeg", "índisfzfce.jpeg", "thchvfj.jpeg"],
}

class InfoVotos extends Component {

  constructor(props) {
    super(props);

    this.state = {
    }
  }






  render() {
    //console.log("dataImages: ", dataImages)
    //console.log("this.props.data[id]: ", this.props.data["id"])
    
    
    
    let images = [
    ];

    if(this.props.data["id"]){
      images = dataImages[this.props.data["id"]].map(i=>{
          return {
            original: "/imgs/"+this.props.data["id"]+"/"+i,
            thumbnail: "/imgs/"+this.props.data["id"]+"/thumb."+i
          }
        })
    }

    return (
      <>
        {this.props.data &&
          <TableContainer component={Paper}>
            <Table  aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="col">
                    Punto
                  </TableCell>
                  <TableCell>
                    {this.props.data["Punto"]}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="col">
                    Población
                  </TableCell>
                  <TableCell>
                    {this.props.data["Población"]}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="col">
                    Fecha
                  </TableCell>
                  <TableCell>
                    {this.props.data["fecha_1"]}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="col">
                    Votación
                  </TableCell>
                  <TableCell>
                    {this.props.data["Votación"]}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        }
        {images.length>0 &&
          <ImageGallery items={images} />
        }
      </>
    )
  }
}




export default InfoVotos;
