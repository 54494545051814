import React, { useState } from "react";
import {
  Grid,
//  LinearProgress,
//  Select,
//  OutlinedInput,
//  MenuItem,
} from "@material-ui/core";
//import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "./styles";

// components
//import mock from "./mock";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
//import { Typography } from "../../components/Wrappers";
//import Dot from "../../components/Sidebar/components/Dot";
//import Table from "./components/Table/Table";
//import BigStat from "./components/BigStat/BigStat";


import MapView from "../../components/MapView";
import InfoVotos from "../../components/InfoVotos";
import TablaVotos from "../../components/TablaVotos";



export default function Dashboard(props) {
  var classes = useStyles();
//  var theme = useTheme();

  // local
//  var [mainChartState, setMainChartState] = useState("monthly");
  var [puntoState, setPuntoState] = useState({});
  var [puntosState, setPuntosState] = useState([]);

  return (
    <>
      <PageTitle title="Votaciones" />
      {puntosState &&
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Widget
              title="Puntos de votación"
              upperTitle
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <TablaVotos data={puntosState}/>
            </Widget>
          </Grid>
        </Grid>
      }
      
      
      
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget
            title="Puntos de votación"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <MapView width={1} onChange={(p)=>{setPuntoState(p)}} onLoad={(puntos)=>{setPuntosState(puntos)}}/>
          </Widget>
        </Grid>
      </Grid>
      
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget
            title="Datos"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <InfoVotos data={puntoState} />
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}

