import React, { Component } from 'react';
//import { Map, TileLayer, Circle, FeatureGroup } from 'react-leaflet';
import { Map, TileLayer, FeatureGroup } from 'react-leaflet';
import Box from '@material-ui/core/Box';
import L from 'leaflet';
import axios from 'axios'; 
//import _ from "lodash";


import data from "./data/mapaVotos.geojson";


class MapView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      z: this.props.zoom ? this.props.zoom : 13,
      c: this.props.center ? this.props.center : [
        4.631630816605021,
        -74.159186592803493
      ],
      anteriorH: -1,
      anteriorW: -1,
      minHeigth: this.props.minHeigth ? this.props.minHeigth : 500,
    }
  }

  update() {
    setTimeout(() => {
      let ref = this.map
      if (ref != null) {
        let h = ref.clientHeight
        if (h < this.state.minHeigth)
          h = this.state.minHeigth
        if (this.state.anteriorH === -1 || this.state.anteriorH === 0 || this.state.anteriorW === -1 || this.state.anteriorW === 0) {
          this.setState({ height: h, width: ref.clientWidth, anteriorH: ref.clientHeight, anteriorW: ref.clientWidth })
        } else {
        }
      }
    }, 1)
  }

  componentDidUpdate(nextProps, nextState) {
    let ref = this.map
    if (
      ref.clientHeight > 0 &&
      ref.clientWidth > 0 &&
      (this.state.height === 0 || this.state.width === 0)
    ) {
      setTimeout(() => {
        this.setState({ height: ref.clientHeight, width: ref.clientWidth, anteriorH: ref.clientHeight, anteriorW: ref.clientWidth })
      }, 500)
    }
  }

  componentDidMount() {
    this.update()    
  }
  
  
  _onEdited = (e) => {

    let numEdited = 0;
    e.layers.eachLayer( (layer) => {
      numEdited += 1;
    });
    console.log(`_onEdited: edited ${numEdited} layers`, e);

    this._onChange();
  }

  _onCreated = (e) => {
    let type = e.layerType;
    //let layer = e.layer;
    if (type === 'marker') {
      // Do marker specific actions
      console.log("_onCreated: marker created", e);
    }
    else {
      console.log("_onCreated: something else created:", type, e);
      console.log("Poligono: ", e.layer.toGeoJSON())
    }
    // Do whatever else you need to. (save to db; etc)

    this._onChange();
  }

  _onDeleted = (e) => {

    let numDeleted = 0;
    e.layers.eachLayer( (layer) => {
      numDeleted += 1;
    });
    console.log(`onDeleted: removed ${numDeleted} layers`, e);

    this._onChange();
  }

  _onMounted = (drawControl) => {
    console.log('_onMounted', drawControl);
  }

  _onEditStart = (e) => {
    console.log('_onEditStart', e);
  }

  _onEditStop = (e) => {
    console.log('_onEditStop', e);
  }

  _onDeleteStart = (e) => {
    console.log('_onDeleteStart', e);
  }

  _onDeleteStop = (e) => {
    console.log('_onDeleteStop', e);
  }
  
  
  _editableFG = null
  
  setPunto(data){
    //console.log("Dando click en el punto: ", data.target.feature.properties)
    this.props.onChange(data.target.feature.properties);
  }

  _onFeatureGroupReady = (reactFGref, thos) => {

    if (reactFGref && this._editableFG===null){
      // populate the leaflet FeatureGroup with the geoJson layers
      axios.get(data).then((request)=>{
        let leafletGeoJSON = new L.GeoJSON(request.data);
        let leafletFG = reactFGref.leafletElement;

        leafletGeoJSON.eachLayer( (layer) => {
          //console.log("Agregando layer ", layer)
          leafletFG.addLayer(layer);
          
          
          layer.on("click", function (event) {
            //var clickedMarker = event.layer;
            thos.setPunto(event)
            // do some stuff…
          });
        });

        // store the ref for future access to content

        this._editableFG = reactFGref;
        this.props.onLoad(request.data.features)
      })
    }
  }

  _onChange = () => {

    // this._editableFG contains the edited geometry, which can be manipulated through the leaflet API

    const { onChange } = this.props;

    if (!this._editableFG || !onChange) {
      return;
    }

    const geojsonData = this._editableFG.leafletElement.toGeoJSON();
    this.props.onChange(geojsonData);
  }
  
  select(){
    console.log("Seleccionado: ", this.state)
    this.props.onAdd(this.state)
  }

  render() {
    return (
      <div className="map" width="100%" >
        <Box width="100%" ref={el => this.map = el} style={{"width": "100%"}}>
          {
            this.state.height &&
            <Map style={{ width: this.state.width, height: this.state.height }} center={this.state.c} zoom={this.state.z} scrollWheelZoom={false}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
              />
              <FeatureGroup ref={ (reactFGref) => {this._onFeatureGroupReady(reactFGref, this);} }>
              </FeatureGroup>
            </Map>
          }
        </Box>
      </div>
    )
  }
}




export default MapView;
