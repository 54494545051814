import React, { Component } from 'react';

//import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

class TablaVotos extends Component {

  constructor(props) {
    super(props);

    this.state = {
    }
  }
/*
{
  id: 7,
  Fecha: "Agosto 7/2020",
  Punto: "Biblioteca Pública Altamar",
  "Población": "Indigenas, Room y comunidad UPZ 82",
  "Votación": "52",
  fecha_1: "2020/08/07"
}
        {this.props.data["Punto"]}
*/
  render() {
    //console.log("this.props.data: ", this.props.data)
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">id</TableCell>
              <TableCell >Punto</TableCell>
              <TableCell >Población</TableCell>
              <TableCell >Fecha</TableCell>
              <TableCell align="right">Votación</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.data && this.props.data.map((row) => (
              <TableRow key={row.properties.id}>
                <TableCell align="right">{row.properties["id"]}</TableCell>
                <TableCell >{row.properties["Punto"]}</TableCell>
                <TableCell >{row.properties["Población"]}</TableCell>
                <TableCell >{row.properties["fecha_1"]}</TableCell>
                <TableCell align="right">{row.properties["Votación"]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}




export default TablaVotos;
